<template>
  <div>
    <heard></heard>
    <div class="Gb_box1">
      <div class="Gb_box1_left">
        <h1 class="Gb_box1_title">
          Multi Business&nbsp;<br />of
          <span class="u-text-custom-color-4">I&nbsp;</span>
          <span class="u-text-custom-color-4">gnite Media</span>
        </h1>
        <p class="Gb_box1_text">
          Except programatic ads, we also<br />
          developed Lifty media: lift<br />
          advertising marketing; and BF<br />
          Media:&nbsp; Global KOL marketing<br />
          Business. Welcome to consult more.
        </p>
        <div class="Gb_box1_btn">
          <a href="http://www.liftymedia.com/" class="Gb_box1_btn1" target="_blank">
            Lifty Media</a>
          <a href="http://www.hellobfm.com/" class="Gb_box1_btn2" target="_blank">&nbsp;BF&nbsp; Media&nbsp;</a>
        </div>
      </div>
      <div class="Gb_box1_right">
        <img src="../assets/Untitled-1-min.jpg" />
      </div>
    </div>
    <div class="Gb_box2">
      <div class="Gb_box2_head">
        <h1 class="Gb_box2_title">Get in Touch!<br /></h1>
        <p class="Gb_box2_text">
          We make marketing easy, efficienct &amp; target oriented!&nbsp;
        </p>
      </div>
      <div class="Gb_box2_content">
        <div class="Gb_box2_item">
          <div class="u-container-layout u-valign-top u-container-layout-1">
            <span class="u-border-3 u-border-grey-15 u-icon u-icon-circle u-text-grey-40 u-icon-1">
              <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 512 512" style="">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-3f5e"></use>
              </svg><svg class="u-svg-content" viewBox="0 0 512 512" x="0px" y="0px" id="svg-3f5e"
                style="enable-background:new 0 0 512 512;">
                <g>
                  <g>
                    <path
                      d="M256,0C156.748,0,76,80.748,76,180c0,33.534,9.289,66.26,26.869,94.652l142.885,230.257    c2.737,4.411,7.559,7.091,12.745,7.091c0.04,0,0.079,0,0.119,0c5.231-0.041,10.063-2.804,12.75-7.292L410.611,272.22    C427.221,244.428,436,212.539,436,180C436,80.748,355.252,0,256,0z M384.866,256.818L258.272,468.186l-129.905-209.34    C113.734,235.214,105.8,207.95,105.8,180c0-82.71,67.49-150.2,150.2-150.2S406.1,97.29,406.1,180    C406.1,207.121,398.689,233.688,384.866,256.818z">
                    </path>
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M256,90c-49.626,0-90,40.374-90,90c0,49.309,39.717,90,90,90c50.903,0,90-41.233,90-90C346,130.374,305.626,90,256,90z     M256,240.2c-33.257,0-60.2-27.033-60.2-60.2c0-33.084,27.116-60.2,60.2-60.2s60.1,27.116,60.1,60.2    C316.1,212.683,289.784,240.2,256,240.2z">
                    </path>
                  </g>
                </g>
              </svg></span>
            <h5 class="u-title ">Customer Service Center</h5>
            <p class="u-text"> 23F,Sahid Sudirman Center, Jl.Jend. Sudirman No.Kav.13-15,Karet Tengsin,Jakarta
              Pusat, Indonesia</p>
          </div>
        </div>
        <div class="Gb_box2_item">
          <div class="u-container-layout u-valign-top u-container-layout-2"><span
              class="u-border-3 u-border-grey-15 u-icon u-icon-circle u-text-grey-40 u-icon-2"><svg class="u-svg-link"
                preserveAspectRatio="xMidYMin slice" viewBox="0 0 479.058 479.058" style="">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-6a13"></use>
              </svg><svg class="u-svg-content" viewBox="0 0 479.058 479.058" id="svg-6a13">
                <path
                  d="m434.146 59.882h-389.234c-24.766 0-44.912 20.146-44.912 44.912v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159l-200.355 173.649-200.356-173.649c1.769-.736 3.704-1.159 5.738-1.159zm0 299.411h-389.234c-8.26 0-14.971-6.71-14.971-14.971v-251.648l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z">
                </path>
              </svg></span>
            <h5 class="u-title">Email:</h5>
            <a href="https://nicepage.dev" class="u-text">cherry@Liftymedia.com</a><br>
            <a href="https://nicepage.com/c/education-website-templates" style="margin: 0;"
              class="u-text">official@liftymedia.com</a>
          </div>
        </div>
        <div class="Gb_box2_item">
          <div class="u-container-layout u-valign-top u-container-layout-3"><span
              class="u-border-3 u-border-grey-15 u-icon u-icon-circle u-text-grey-40 u-icon-3"><svg class="u-svg-link"
                preserveAspectRatio="xMidYMin slice" viewBox="0 0 384 384" style="">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-a3bc"></use>
              </svg><svg class="u-svg-content" viewBox="0 0 384 384" x="0px" y="0px" id="svg-a3bc"
                style="enable-background:new 0 0 384 384;">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.906-3.719-23.323-0.833-30.438,6.417l-43.177,32.594    c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448    c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.812C13.823,0,0,13.823,0,30.812    C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z     M362.667,353.188c0,5.229-4.25,9.479-9.479,9.479c-182.99,0-331.854-148.865-331.854-331.854c0-5.229,4.25-9.479,9.479-9.479    h70.521c5.229,0,9.479,4.25,9.479,9.479c0,25.802,4.052,51.125,11.979,75.115c1.104,3.542,0.208,7.208-3.375,10.938L82.75,165.427    c-2.458,3.26-2.844,7.625-1,11.26c29.927,58.823,66.292,95.188,125.531,125.542c3.604,1.885,8.021,1.49,11.292-0.979    l49.677-37.635c2.51-2.51,6.271-3.406,9.667-2.25c24.156,7.979,49.479,12.021,75.271,12.021c5.229,0,9.479,4.25,9.479,9.479    V353.188z">
                    </path>
                  </g>
                </g>
              </svg></span>
            <h5 class="u-title">Call us:</h5>
            <p class="u-text">+62 81363208956&nbsp;<br>+86 13662609434
            </p>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import heard from "../components/heard.vue";
import foot from "../components/foot.vue";
export default {
  components: {
    heard,
    foot,
  },
};
</script>

<style scoped>
.Gb_box1 {
  width: 1140px;
  margin: auto;
  display: flex;
}

.Gb_box1_left {
  width: 508px;
  min-height: 563px;
}

.Gb_box1_left .Gb_box1_title {
  font-weight: 700;
  line-height: 52.8px;
  letter-spacing: normal;
  font-family: Roboto, sans-serif;
  font-size: 3rem;
  margin-top: 105px;
}

.Gb_box1_left .Gb_box1_text {
  margin-top: 23px;
  font-size: 1.25rem;
}

.Gb_box1_btn {
  display: flex;
}

.Gb_box1_btn a {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.25rem;
  background-image: none;
  text-align: center;
  font-weight: 700;
  border-style: solid;
  padding: 16px 25px 16px 24px;
  border-color: #404040;
  color: #404040;
  background-color: transparent;
  border-radius: 10px;
  display: inline-block;
  margin-right: 20px;
  margin-top: 51px;
}

.Gb_box1_btn a:hover {
  border-color: #ff5100;
  background-color: #ff5100;
  color: #ffffff;
}

.Gb_box1_right {
  width: 693px;
  height: 540px;
}

.Gb_box1_right img {
  width: 693px;
  height: 540px;
  margin-top: 75px;
}

.Gb_box2 {
  color: #ffffff;
  background-color: #3c065e;
  width: 100%;
  margin-top: 126px;
  padding-top: 23px;
  padding-bottom: 30px;
}

.Gb_box2_head {
  width: 1140px;
  margin: auto;
  text-align: center;
}

.Gb_box2_title {
  font-size: 3rem;
  line-height: 1.1;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: none;
  margin-bottom: 10px;
}

.Gb_box2_text {
  font-size: 1.125rem;
}

.Gb_box2_content {
  width: 1140px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
}

.Gb_box2_item {
  padding: 30px 20px;
  color: #111111;
  background-color: #ffffff;
  width: 28.2%;
  text-align: center;
}

.Gb_box2_item .u-title {
  font-weight: 700;
  text-transform: none;
  font-size: 1.25rem;
  margin-top: 25px;
}

.Gb_box2_item .u-text {
  margin-top: 16px;
}

.Gb_box2_item a {
  display: inline-block;
  color: #111111;
  text-decoration: underline !important;
}

.u-svg-link {
  fill: #999999;
  width: 34px;
  height: 34px;
}

.u-icon {
  border: 3px solid #d9d9d9;
  height: 75px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}</style>