<template>
  <div>
    <heard></heard>
    <div class="main">
      <div class="Ab_box1">
        <div class="Ab_box1_left">
          <h1 class="Ab_box1_title">
            <span> About&nbsp;</span>
            <span class="u-text-custom-color-4">
              <br>Ignite Team
            </span>
          </h1>
          <p class="Ab_box1_text"> Ignite Meida team is established by a group of enthusias geeks in Singapore 2018, We
            are born to be international, we believe that technology could change the world, and make digital-marketing
            more efficient.</p>
          <div class="area">
            <a href="https://nicepage.com/c/slider-website-templates" class="">Singapore</a>
            <a href="https://nicepage.com/c/slider-website-templates" class="">Indonesia</a><br>
            <a href="https://nicepage.com/c/slider-website-templates" class="">HongKong</a>
            <a href="https://nicepage.com/c/slider-website-templates" class="u-btn-4">&nbsp; &nbsp; China&nbsp;
              &nbsp;&nbsp;</a><br>
            <a href="https://nicepage.com/c/slider-website-templates" class="u-btn-5">Expanding...</a>
          </div>
        </div>
        <div class="Ab_box1_right">
          <img src="../assets/IgniteGroup.jpg">
        </div>
      </div>
      <div class="Ab_box2">
        <div class="Ab_box2_left">
          <img class="logo" src="../assets/Ignitemedialogo1.png">
          <h1 class="Ab_box2_title">Fo
            <span style="color: #3c065e">cus on International</span>
            <span class="u-text-custom-color-4">
              Marketing solution
            </span>
          </h1>
          <p class="Ab_box2_text">Since Ignite Media's establishment, we focus on making&nbsp;&nbsp;<br>marketing&nbsp;
            activity
            easy, efficient, and data-oriented
          </p>
          <a href="https://nicepage.dev" class="Ab_box2_btn">Ignite media&nbsp; Milestone<br></a>
        </div>
        <div class="Ab_box2_right">
          <img class="Ab_img0" src="../assets/dowoad3.png">
          <img class="Ab_img1" src="../assets/223456.png">
          <img class="Ab_img2" src="../assets/Ignitemediadslogo.png">
          <img class="Ab_img3" src="../assets/134567.png">
          <img class="Ab_img4" src="../assets/Ignitedas.png">
          <img class="Ab_img5" src="../assets/IgniteDsplogo12.png">
          <img class="Ab_img6" src="../assets/logo.png">
          <p class="Ab_text1">Ignite SDK<br> Start develop Own product, <br>and develop ignite ads SDK. <br>
          </p>
          <p class="Ab_text2">Pre-A fund raising<br> Finish Pre-A fund-raising with Vivo<br> venture capital, expand
            business
            to<br> CIS/MENA market<br>
          </p>
          <p class="Ab_text3"> lgnite SSP<br>Develop Ignite SSP Platform, Expand<br> team to China and build office,
            keep<br>
            expand international team&nbsp;<br>
            <br>
          </p>
          <p class="Ab_text4"> lgnite DSP &amp; Lifty Media<br>Established in Singapore, Expand Offline<br> business Lifty
            media In Indonesia, JKT.<br> Meanwhile Start Develop Ignie DSP Platform.
          </p>
        </div>
      </div>
      <div class="Ab_box3">
        <h2 class="">
          Trusted by&nbsp;<span class="u-text-custom-color-4">800+</span>
          partners
        </h2>
        <img src="../assets/1.png">
      </div>
    </div>

    <foot></foot>
  </div>
</template>

<script>
import heard from "../components/heard.vue";
import foot from "../components/foot.vue";
export default {
  components: {
    heard,
    foot,
  },
}
</script>

<style scoped>
.main {
  width: 1140px;
  margin: auto;
}

.Ab_box1 {
  display: flex;
}

.Ab_box1_left {
  width: 486px;
}

.Ab_box1_left .Ab_box1_title {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 1.1;
  margin-top: 55px;
  margin-left: 65px;
}

.Ab_box1_left .Ab_box1_text {
  width: 365px;
  font-size: 1.11111rem;
  font-style: normal;
  margin-top: 51px;
  margin-left: 65px;
}

.Ab_box1_left .area {
  width: 389px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 38px;
  margin-left: 65px;
}

.Ab_box1_left .area a {
  color: #111111;
  background-color: #c0c0c0;
  font-weight: 700;
  font-size: 0.9375rem;
  padding: 8px 33px;
  border-radius: 6px;
  margin-bottom: 20px;
  /* margin-right: 55px; */
}

.Ab_box1_left .area .u-btn-5 {
  padding: 8px 27px 8px 25px;
}

.Ab_box1_left .area .u-btn-4 {
  padding: 8px 33px;
}

.Ab_box1_left .area a:hover {
  color: #ffffff;
  background-color: #ff5100;
}

.Ab_box1_right {
  width: 702px;
}

.Ab_box1_right img {
  width: 702px;
  height: 467px;
  margin-left: 22px;
  margin-top: 39px;
}


.Ab_box2 {
  display: flex;
  position: relative;
}

.Ab_box2_left {
  width: 570px;
  position: absolute;
  top: 0;
}

.Ab_box2_title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.1;
  margin-top: 29px;
  margin-left: 71px;
}

.Ab_box2_text {
  font-size: 1.125rem;
  margin-top: 29px;
  margin-left: 71px;
}

.Ab_box2_btn {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  border-style: none;
  font-weight: 700;
  background-color: #3c065e;
  color: #ffffff;
  padding: 9px 24px 11px 23px;
  border-radius: 50px;
  margin-left: 71px;
  margin-top: 82px;
}

.Ab_box2_btn:hover {
  background-color: #ff5100;
}

.Ab_box2_right {
  width: 909px;
  height: 625px;
  margin-left: 186px;
  margin-top: 64px;
  position: relative;
  font-size: 0.875rem;
  color: #ffffff;
}

.Ab_box2_left .logo {
  width: 302px;
  height: 97px;
  margin-top: 86px;
  margin-left: 44px;
}

.Ab_box2_right .Ab_img0 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Ab_box2_right .Ab_img1 {
  position: absolute;
  top: 48px;
  right: 51px;
  width: 40px;
  height: 40px;

}

.Ab_box2_right .Ab_img2 {
  position: absolute;
  top: 87px;
  right: -13px;
  width: 145px;
  height: 46px;
}

.Ab_box2_right .Ab_img3 {
  position: absolute;
  top: 188px;
  right: 40px;
  width: 133px;
  height: 43px;
}

.Ab_box2_right .Ab_img4 {
  position: absolute;
  top: 319px;
  right: 17px;
  width: 137px;
  height: 76px;
}

.Ab_box2_right .Ab_img5 {
  position: absolute;
  bottom: 102px;
  right: 34px;
  width: 121px;
  height: 49px;
}

.Ab_box2_right .Ab_img6 {
  position: absolute;
  bottom: 50px;
  right: 39px;
  width: 106px;
  height: 52px;
}

.Ab_box2_right .Ab_text1 {
  position: absolute;
  top: 121px;
  right: 13px;
}

.Ab_box2_right .Ab_text2 {
  position: absolute;
  top: 230px;
  right: 45px;
}

.Ab_box2_right .Ab_text3 {
  position: absolute;
  bottom: 154px;
  right: 151px;
}

.Ab_box2_right .Ab_text4 {
  position: absolute;
  bottom: 37px;
  right: 214px;
}


.Ab_box3{
  text-align: center;
  padding-bottom: 27px;
}
.Ab_box3>h2{
  font-size: 2.25rem;
    line-height: 1.1;
    font-weight: 700;
    padding-top: 61px;
    padding-bottom: 38px;
}
.Ab_box3>img{
  width: 947px;
    height: 526px;
}
</style>