var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heard'),_c('div',{staticClass:"main"},[_c('div',{staticClass:"Ad_box1"},[_c('div',{staticClass:"Ad_box1_left"},[_vm._m(0),_vm._m(1),_c('router-link',{staticClass:"u-active",attrs:{"to":"/Contact"}},[_vm._v("Contact Us")])],1),_vm._m(2)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"Ad_box6"},[_c('serverCenter')],1)]),_c('foot'),(_vm.top)?_c('span',{staticClass:"istop",on:{"click":_vm.istop}},[_c('svg',{staticClass:"u-svg-link",attrs:{"preserveAspectRatio":"xMidYMin slice","viewBox":"0 0 551.13 551.13"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"#svg-1d98"}})]),_c('svg',{staticClass:"u-svg-content",attrs:{"enable-background":"new 0 0 551.13 551.13","viewBox":"0 0 551.13 551.13","xmlns":"http://www.w3.org/2000/svg","id":"svg-1d98"}},[_c('path',{attrs:{"d":"m275.565 189.451 223.897 223.897h51.668l-275.565-275.565-275.565 275.565h51.668z"}})])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"u-text u-text-1"},[_vm._v(" One-Stop "),_c('br'),_c('span',{staticClass:"u-text-custom"},[_vm._v("AdTech  ")]),_c('span',{staticClass:"u-text-custom"},[_vm._v("Platform")]),_vm._v(" "),_c('br'),_vm._v("Maximize your Ads Roi ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"u-text-2"},[_vm._v(" Ignite Media help agencies and brands plan, execute,"),_c('br'),_vm._v(" and optimize high-performing ad campaigns  ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Ad_box1_right"},[_c('img',{attrs:{"src":require("../assets/download.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Ad_box2"},[_c('div',{staticClass:"data_item"},[_c('a',{staticClass:"u-btn",attrs:{"href":"../page/Contact.vue"}},[_vm._v(" 500 + ")]),_c('h4',{staticClass:"data_title"},[_vm._v("Apps")]),_c('div',{staticClass:"data_text"},[_vm._v(" Ignite SSP have expanded more than 500 Direct Publishers , with more than 600 Direct cooperated Apps. ")])]),_c('div',{staticClass:"data_item"},[_c('a',{staticClass:"u-btn",attrs:{"href":"../page/Contact.vue"}},[_vm._v("  50 +  ")]),_c('h4',{staticClass:"data_title"},[_vm._v("Countries")]),_c('div',{staticClass:"data_text"},[_vm._v(" Ignite SSP's Publisher Traffic have expand more than 50 countries all over the world,  and it's keep expanding. ")])]),_c('div',{staticClass:"data_item"},[_c('a',{staticClass:"u-btn",attrs:{"href":"../page/Contact.vue"}},[_vm._v(" 600M + ")]),_c('h4',{staticClass:"data_title"},[_vm._v("Users")]),_c('div',{staticClass:"data_text"},[_vm._v(" With hundreds of Publisher's direc traffic, Ignite Media could reach 600M + User impression everyday. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Ad_box3"},[_c('div',{staticClass:"Ad_box3_left"},[_c('img',{attrs:{"src":require("../assets/11.png")}})]),_c('div',{staticClass:"Ad_box3_right"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/Ignite.png")}}),_c('h1',{staticClass:"Ad_box3_title"},[_vm._v(" Direct "),_c('span',{staticClass:"u-text-custom-color-4"},[_c('br'),_vm._v("Publisher Traffic")])]),_c('p',{staticClass:"Ad_box3_text"},[_vm._v(" Ignite SSP part expand and cooperate with Hundreds of Global publishers by Ignite SDK, RTB, Java Script tools etc, Meanwhile Ignite Media have own team to develop Self App product. ")]),_c('img',{staticClass:"logo2",attrs:{"src":require("../assets/3.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Ad_box4"},[_c('div',{staticClass:"Ad_box4_head"},[_c('img',{attrs:{"src":require("../assets/IgniteADX.png")}}),_c('h2',{staticClass:"u-text"},[_vm._v("Ad  Format")])]),_c('p',{staticClass:"Ad_box4_text"},[_vm._v(" Ignite Adx support different type of  Ad formats"),_c('br'),_vm._v("we have experience from thousands Programatic Ads Campaigns ")]),_c('img',{staticClass:"phone_type",attrs:{"src":require("../assets/87652.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Ad_box5"},[_c('div',{staticClass:"Ad_box5_left"},[_c('div',{staticClass:"Ad_box5_left_head"},[_c('img',{attrs:{"src":require("../assets/223456.png")}}),_c('h1',{staticClass:"u-text u-text-1"},[_vm._v("Iwawa")])]),_c('h1',{staticClass:"Ad_box5_h1"},[_vm._v(" Ignite Media "),_c('span',{staticClass:"u-text-custom-color-4"},[_c('br'),_vm._v("In-house App ")]),_c('br'),_vm._v("Traffic ")]),_c('p',{staticClass:"Ad_box5_text"},[_vm._v(" Ignite Media offer Multi-channel solution for Publishers and Advertisers. Meanwhile our self-developed product also achieve good user growth. "),_c('br')]),_c('img',{staticClass:"logo2",attrs:{"src":require("../assets/3.png")}})]),_c('div',{staticClass:"Ad_box5_right"},[_c('img',{attrs:{"src":require("../assets/22345.png")}})])])
}]

export { render, staticRenderFns }