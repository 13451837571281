<template>
  <div class="" style="display: flex;">
    <div class="Ad_box6_left">
          <img src="../assets/212.png" />
          <span class="u-icon u-text-palette-1-base u-icon-1"
            ><svg
              class="u-svg-link"
              preserveAspectRatio="xMidYMin slice"
              viewBox="0 0 52 52"
              style=""
            >
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#svg-e6cf"
              ></use></svg><svg
              class="u-svg-content"
              viewBox="0 0 52 52"
              x="0px"
              y="0px"
              id="svg-e6cf"
              style="enable-background: new 0 0 52 52"
            >
              <path
                style="fill: currentColor"
                d="M38.853,5.324L38.853,5.324c-7.098-7.098-18.607-7.098-25.706,0h0
	C6.751,11.72,6.031,23.763,11.459,31L26,52l14.541-21C45.969,23.763,45.249,11.72,38.853,5.324z M26.177,24c-3.314,0-6-2.686-6-6
	s2.686-6,6-6s6,2.686,6,6S29.491,24,26.177,24z"
              ></path></svg></span>
          <span class="u-icon u-text-palette-1-base u-icon-2"
            ><svg
              class="u-svg-link"
              preserveAspectRatio="xMidYMin slice"
              viewBox="0 0 52 52"
              style=""
            >
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#svg-e6cf"
              ></use></svg><svg
              class="u-svg-content"
              viewBox="0 0 52 52"
              x="0px"
              y="0px"
              id="svg-e6cf"
              style="enable-background: new 0 0 52 52"
            >
              <path
                style="fill: currentColor"
                d="M38.853,5.324L38.853,5.324c-7.098-7.098-18.607-7.098-25.706,0h0
        C6.751,11.72,6.031,23.763,11.459,31L26,52l14.541-21C45.969,23.763,45.249,11.72,38.853,5.324z M26.177,24c-3.314,0-6-2.686-6-6
        s2.686-6,6-6s6,2.686,6,6S29.491,24,26.177,24z"
              ></path></svg></span>
          <span class="u-icon u-text-palette-1-base u-icon-3"
            ><svg
              class="u-svg-link"
              preserveAspectRatio="xMidYMin slice"
              viewBox="0 0 52 52"
              style=""
            >
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#svg-e6cf"
              ></use></svg><svg
              class="u-svg-content"
              viewBox="0 0 52 52"
              x="0px"
              y="0px"
              id="svg-e6cf"
              style="enable-background: new 0 0 52 52"
            >
              <path
                style="fill: currentColor"
                d="M38.853,5.324L38.853,5.324c-7.098-7.098-18.607-7.098-25.706,0h0
        C6.751,11.72,6.031,23.763,11.459,31L26,52l14.541-21C45.969,23.763,45.249,11.72,38.853,5.324z M26.177,24c-3.314,0-6-2.686-6-6
        s2.686-6,6-6s6,2.686,6,6S29.491,24,26.177,24z"
              ></path></svg></span>
          <span class="u-icon u-text-palette-1-base u-icon-4"
            ><svg
              class="u-svg-link"
              preserveAspectRatio="xMidYMin slice"
              viewBox="0 0 52 52"
              style=""
            >
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#svg-e6cf"
              ></use></svg><svg
              class="u-svg-content"
              viewBox="0 0 52 52"
              x="0px"
              y="0px"
              id="svg-e6cf"
              style="enable-background: new 0 0 52 52"
            >
              <path
                style="fill: currentColor"
                d="M38.853,5.324L38.853,5.324c-7.098-7.098-18.607-7.098-25.706,0h0
        C6.751,11.72,6.031,23.763,11.459,31L26,52l14.541-21C45.969,23.763,45.249,11.72,38.853,5.324z M26.177,24c-3.314,0-6-2.686-6-6
        s2.686-6,6-6s6,2.686,6,6S29.491,24,26.177,24z"
              ></path></svg></span>
        </div>
        <div class="Ad_box6_right">
          <img class="Ad_box6_right_logo" src="../assets/IgniteADX.png" />
          <h1 class="Ad_box6_right_title">
            Our ADX&nbsp;<span class="u-text-custom-color-4">
              <br />Server Center&nbsp;
            </span>
          </h1>
          <p class="u-large-text">
            · US West&nbsp; &nbsp; &nbsp;Data Center&nbsp;<br />· US East&nbsp;
            &nbsp; &nbsp; Data Center&nbsp;<br />· Frankfurt&nbsp; &nbsp;Data
            Center&nbsp;<br />· Singapore&nbsp; Data Center&nbsp;
          </p>
        </div>
  </div>
</template>

<script>
export default {
  name: 'serverCenter ',
  props: {
    
  }
}
</script>
<style scoped>

.Ad_box6_left {
  margin-left: 25px;
  margin-top: 63px;
  position: relative;
}

.Ad_box6_left img {
  width: 570px;
  height: 333px;
}

.Ad_box6_right {
  margin-left: 84px;
}

.Ad_box6_right_logo {
  width: 232px;
  height: 98px;
}

.Ad_box6_right_title {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 1.1;
  margin-left: 14px;
  margin-top: 19px;
}
.Ad_box6_right .u-large-text {
  margin-top: 19px;
  font-size: 1.5rem;
  font-style: normal;
  margin-left: 14px;
  margin-top: 40px;
}

.u-icon {
  width: 59px;
  height: 59px;
  display: block;
  line-height: 0;
  border-width: 0px;
}
.u-icon-1 {
  color: #478ac9 !important;
  position: absolute;
  top: 90px;
  left: 125px;
}
.u-icon-2 {
  color: #f5d654 !important;
  position: absolute;
  top: 114px;
  left: 54px;
}
.u-icon-3 {
  color: #ff5100 !important;
  position: absolute;
  top: 76px;
  left: 253px;
}
.u-icon-4 {
  color: #2cccc4 !important;
  position: absolute;
  top: 174px;
  left: 413px;
}
.u-icon-1:after,
.u-icon-2:after,
.u-icon-3:after,
.u-icon-4:after
{
    position: absolute;
    bottom: -2px;
    left: 29px;
    right: -422px;
    content: '';
    box-sizing: border-box;
    height: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    transform: scaleY(0.9); 
}
.u-icon-2:after{
    right: -493px;
}
.u-icon-3:after{
    right: -293px;
}
.u-icon-4:after{
    right: -133px;
}
.u-svg-link {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.u-svg-content {
  width: 0 !important;
  height: 0 !important;
}
.u-icon-circle {
  border-radius: 50%;
}
</style>
