<template>
  <div>
    <heard></heard>
    <div class="main">
      <div class="Ad_box1">
        <div class="Ad_box1_left">
          <h1 class="u-text u-text-1">
            One-Stop&nbsp;<br />
            <span class="u-text-custom">AdTech&nbsp;&nbsp;</span>
            <span class="u-text-custom">Platform</span>&nbsp;<br />Maximize your
            Ads Roi
          </h1>
          <p class="u-text-2">
            Ignite Media help agencies and brands plan, execute,<br />
            and optimize high-performing ad campaigns&nbsp;
          </p>
          <router-link to="/Contact" class="u-active">Contact Us</router-link>
        </div>
        <div class="Ad_box1_right">
          <img src="../assets/download.png" />
        </div>
      </div>
      <div class="Ad_box2">
        <div class="data_item">
          <a href="../page/Contact.vue" class="u-btn">&nbsp;500 +&nbsp;</a>
          <h4 class="data_title">Apps</h4>
          <div class="data_text">
            Ignite SSP have expanded more than 500 Direct Publishers , with more
            than 600 Direct cooperated Apps.
          </div>
        </div>
        <div class="data_item">
          <a href="../page/Contact.vue" class="u-btn">&nbsp;&nbsp;50 +&nbsp;&nbsp;</a>
          <h4 class="data_title">Countries</h4>
          <div class="data_text">
            Ignite SSP's Publisher Traffic have expand more than 50 countries
            all over the world, &nbsp;and it's keep expanding.
          </div>
        </div>
        <div class="data_item">
          <a href="../page/Contact.vue" class="u-btn">&nbsp;600M +&nbsp;</a>
          <h4 class="data_title">Users</h4>
          <div class="data_text">
            With hundreds of Publisher's direc traffic, Ignite Media could reach
            600M + User impression everyday.
          </div>
        </div>
      </div>
      <div class="Ad_box3">
        <div class="Ad_box3_left">
          <img src="../assets/11.png" />
        </div>
        <div class="Ad_box3_right">
          <img class="logo" src="../assets/Ignite.png" />
          <h1 class="Ad_box3_title">
            Direct&nbsp;<span class="u-text-custom-color-4"><br />Publisher Traffic</span>
          </h1>
          <p class="Ad_box3_text">
            Ignite SSP part expand and cooperate with Hundreds of Global
            publishers by Ignite SDK, RTB, Java Script tools etc, Meanwhile
            Ignite Media have own team to develop Self App product.
          </p>
          <img class="logo2" src="../assets/3.png" />
        </div>
      </div>
      <div class="Ad_box4">
        <div class="Ad_box4_head">
          <img src="../assets/IgniteADX.png" />
          <h2 class="u-text">Ad&nbsp; Format</h2>
        </div>
        <p class="Ad_box4_text">
          Ignite Adx support different type of&nbsp; Ad formats<br />we
          have&nbsp;experience from thousands Programatic Ads Campaigns
        </p>
        <img class="phone_type" src="../assets/87652.png" />
      </div>
      <div class="Ad_box5">
        <div class="Ad_box5_left">
          <div class="Ad_box5_left_head">
            <img src="../assets/223456.png" />
            <h1 class="u-text u-text-1">Iwawa</h1>
          </div>
          <h1 class="Ad_box5_h1">
            Ignite Media
            <span class="u-text-custom-color-4"> <br />In-house App </span>
            <br />Traffic
          </h1>
          <p class="Ad_box5_text">
            Ignite Media offer Multi-channel solution for Publishers and
            Advertisers. Meanwhile our self-developed product also achieve good
            user growth.
            <br />
          </p>
          <img class="logo2" src="../assets/3.png" />
        </div>
        <div class="Ad_box5_right">
          <img src="../assets/22345.png" />
        </div>
      </div>
      <div class="Ad_box6">
        <serverCenter></serverCenter>
      </div>
    </div>
    <foot></foot>
    <span v-if="top" class="istop" @click="istop">
      <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 551.13 551.13">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-1d98"></use>
      </svg>
      <svg class="u-svg-content" enable-background="new 0 0 551.13 551.13" viewBox="0 0 551.13 551.13"
        xmlns="http://www.w3.org/2000/svg" id="svg-1d98">
        <path d="m275.565 189.451 223.897 223.897h51.668l-275.565-275.565-275.565 275.565h51.668z"></path>
      </svg>
    </span>
  </div>
</template>

<script>
import heard from "../components/heard.vue";
import foot from "../components/foot.vue";
import serverCenter from "../components/serverCenter.vue";
export default {
  components: {
    heard,
    foot,
    serverCenter
  },
  data() {
    return {
      top: false
    };
  },
  mounted() {
    var that = this;
    window.addEventListener('scroll', function () {//监听滚动
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 108) {
        //如果当前滚动位置大于您的所需要达到的高度则显示置顶元素，反之不显示
        that.top = true;
      } else {
        that.top = false;
      }
    });
  },

  methods: {
    istop() {
      var timer = setInterval(() => 
      document.documentElement.scrollTop <= 0 ? clearInterval(timer) : window.scrollTo(0, document.documentElement.scrollTop - 100), 17);
    }
  }
};
</script>

<style scoped>
.main {
  /* height: 3413px; */
  width: 1140px;
  margin: auto;
}

.Ad_box1 {
  display: flex;
  margin-top: 20px;
}

.Ad_box1_left {
  max-width: 40%;
  min-width: 40%;
}

.Ad_box1_left .u-text {
  font-weight: 700;
  font-size: 44px;
  margin: 0;
  line-height: 1.1;
  margin-top: 39px;
}

.Ad_box1_left .u-text-2 {
  margin-top: 61px;
  margin-left: 4px;
}

.Ad_box1_left .u-active {
  border-width: 2px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  background-image: none;
  text-align: center;
  font-weight: 700;
  border-style: solid;
  padding: 20px 47px 20px 46px;
  border-color: #404040;
  color: #404040;
  background-color: transparent;
  border-radius: 10px;
  display: inline-block;
  margin-top: 71px;
  margin-left: 5px;
}

.Ad_box1_left .u-active:hover {
  border-color: #ff5100;
  color: #ffffff;
  background-color: #ff5100;
}

.Ad_box1_left .u-text-custom {
  color: #ff5100 !important;
}

.Ad_box1_right {
  max-width: 60%;
  min-width: 60%;
}

.Ad_box1_right img {
  height: 469px;
  width: 670px;
  margin-left: 14px;
  margin-top: 32px;
  object-fit: cover;
  display: block;
}

.Ad_box2 {
  display: flex;
  align-items: center;
  margin-top: 70px;
}

.Ad_box2 .data_item {
  text-align: center;
  padding: 0 23px;
}

.Ad_box2 .data_item .u-btn {
  border-width: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  border-style: solid;
  padding: 5px 22px 6px;
  border-color: #111111;
  color: #111111;
  background-color: transparent;
  border-radius: 50px;
}

.Ad_box2 .data_item .u-btn:hover {
  border-color: #ff5100;
  color: #ffffff;
  background-color: #ff5100;
}

.Ad_box2 .data_item h4 {
  letter-spacing: normal;
  text-transform: none;
  font-weight: 700;
  font-size: 24px;
  color: #ff5100;
  font-family: Montserrat, sans-serif !important;
  margin-top: 16px;
}

.Ad_box2 .data_item .data_text {
  color: #999999;
}

.Ad_box3 {
  display: flex;
  margin-top: 115px;
}

.Ad_box3_left img {
  width: 636px;
  height: 591px;
  margin-left: 18px;
}

.Ad_box3_right {
  padding-left: 25px;
}

.Ad_box3_right .logo {
  width: 232px;
  height: 85px;
}

.Ad_box3_right .logo2 {
  width: 343px;
  height: 97px;
  margin-left: 5px;
  margin-top: 26px;
}

.Ad_box3_title {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 1.1;
  margin-top: 15px;
  margin-left: 15px;
}

.Ad_box3_text {
  margin-left: 20px;
  font-size: 1.1111rem;
  font-style: normal;
  color: #111111;
  width: 361px;
  margin-top: 45px;
}

.Ad_box4 {
  margin-top: 54px;
}

.Ad_box4_head {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Ad_box4_head img {
  width: 223px;
  height: 90px;
  margin-right: 20px;
}

.Ad_box4_head .u-text {
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 1.1;
  margin-right: 28px;
  display: inline-block;
  font-family: Roboto, sans-serif;
}

.Ad_box4_text {
  text-align: center;
  font-size: 1.125rem;
  margin-top: 14px;
}

.Ad_box4 .phone_type {
  width: 100%;
  margin-top: 36px;
}

.Ad_box5 {
  display: flex;
  margin-top: 126px;
}

.Ad_box5_left {
  width: calc(486px - 78px);
  margin-left: 78px;
}

.Ad_box5_left_head {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.Ad_box5_left_head img {
  width: 88px;
  height: 88px;
  margin-right: 26px;
}

.Ad_box5_left_head h1 {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.1;
}

.Ad_box5_h1 {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 1.1;
  margin-top: 35px;
}

.Ad_box5_text {
  width: 336px;
  font-size: 1.11111rem;
  font-style: normal;
  margin-top: 32px;
}

.Ad_box5_left .logo2 {
  width: 343px;
  height: 97px;
  margin-left: -9px;
  margin-top: 10px;
}

.Ad_box5_right {
  width: 570px;
  height: 564px;
  background-image: url("../assets/2rrgsdf.png");
  background-size: 100% 100%;
  margin-left: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Ad_box5_right img {
  width: 480px;
  height: 504px;
}

.Ad_box6 {
  margin-top: 108px;
  display: flex;
  margin-bottom: 134px;
}

.u-svg-link {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.u-svg-content {
  width: 0 !important;
  height: 0 !important;
}

.istop {
  background-image: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 17px 20px;
  color: #ffffff;
  background-color: #064f87;
  z-index: 100000;
  border-radius: 50%;
  cursor: pointer;
}

.istop>svg {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 1;
  vertical-align: -0.15em;
}

.istop>svg:not(:root) {
  overflow: hidden;
}
</style>