<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.6;
  font-family: 'Open Sans', sans-serif;

}

a {
  text-decoration: none;
}
p {
  margin: 0;
  padding: 0;
}
img {
  object-fit: contain;
  background-size: contain;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
}
.u-text-custom-color-4 {
  color: #ff5100;
}
.u-svg-link {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.u-svg-content {
  width: 0 !important;
  height: 0 !important;
}
.u-icon-circle {
  border-radius: 50%;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
   */
}</style>
