<template>
  <div class="heard">
    <a href="">
      <img src="../assets/Ignitemedialogo1.png" alt="" />
    </a>
    <div class="Nav">{{ active }}
      <router-link to="/index" class="Navitem">Advertiser</router-link>
      <router-link to="/Publisher" class="Navitem">Publisher</router-link>
      <router-link to="/About_Us" class="Navitem">About Us</router-link>
      <router-link to="/Group_Business" class="Navitem">Group Business</router-link>
      <router-link to="/Contact" class="Navitem">Contact</router-link>
      <div class="Navitem tolog"><a href="http://8.217.147.26:88/#/register" target="_blank">Register</a> / <a href="http://8.217.147.26:88/#/login" target="_blank">Log on</a></div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "heard",
  props: {
    active: String,
  },
  data(){
    return{
      navlist:['Advertiser','Publisher','About Us','Group Business','Contact']
    }
  }
};
</script>
  

<style scoped>
.heard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1140px;
  margin: auto;
  padding-top: 22px;
}

img {
  width: 293px;
  height: 107px;
}

.Nav {
  margin-top: 6px;
  margin-right: 12px;
  display: flex;
  align-content: center;
}

.Navitem {
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 700;
  color: #111111;
  padding: 10px;
  margin-right: 20px;
}
a.router-link-active{
  color: #ff5100;
}
.tolog a{
  cursor: pointer;
  color: #111111;

}
</style>
  