<template>
  <div class="foot">
    <div class="box">
      <div class="boxLeft">
        <img class="footlogo" src="../assets/Ignitemedialogosdf.png" alt="" />
        <div class="follow">
          <p>Follow us</p>
          <img
            class="facebook"
            src="../assets/10-104225_icon-social-media-linkedin-facebook-twitter-facebook-linkedin.png"
            alt=""
          />
        </div>
        <div class="boxleftText">
          Copyrights @ 2018 Ignite Media HK Ltd All rights reserved
        </div>
      </div>
      <div class="boxRight">
        <div class="boxRight_item">
          <div class="boxRight_dw">
            <img class="dw" src="../assets/_20230208111655.png" alt="" />
            <span>HongKong </span>
          </div>
          <div class="boxRight_tex">
            Room 603E,6/F,Hang Pont Commercial Building,31 Tonkin Street, Cheung
            Sha Wan,Kowloon,Hongkong
          </div>
        </div>
        <div class="boxRight_item">
          <div class="boxRight_dw">
            <img class="dw" src="../assets/_20230208111655.png" alt="" />
            <span>Singapore </span>
          </div>
          <div class="boxRight_tex">
            10 ANSON ROAD #27 - 18 INTERNATIONAL PLAZA SINGAPORE (079903)
          </div>
        </div>
        <div class="boxRight_item boxRight_item03">
          <div class="boxRight_dw">
            <img class="dw" src="../assets/_20230208111655.png" alt="" />
            <span>Indonesia </span>
          </div>
          <div class="boxRight_tex">
            23F,Sahid Sudirman Center, Jl.Jend. Sudirman No.Kav.13-15,Karet
            Tengsin,Jakarta Pusat, Indonesia
          </div>
        </div>
        <div class="boxRight_item boxRight_item04">
            <div class="boxRight_t"> <svg class="u-svg-content" viewBox="0 0 24 16" x="0px" y="0px" style="width: 1em; height: 1em;"><path fill="currentColor" d="M23.8,1.1l-7.3,6.8l7.3,6.8c0.1-0.2,0.2-0.6,0.2-0.9V2C24,1.7,23.9,1.4,23.8,1.1z M21.8,0H2.2
	c-0.4,0-0.7,0.1-1,0.2L10.6,9c0.8,0.8,2.2,0.8,3,0l9.2-8.7C22.6,0.1,22.2,0,21.8,0z M0.2,1.1C0.1,1.4,0,1.7,0,2V14
	c0,0.3,0.1,0.6,0.2,0.9l7.3-6.8L0.2,1.1z M15.5,9l-1.1,1c-1.3,1.2-3.6,1.2-4.9,0l-1-1l-7.3,6.8c0.2,0.1,0.6,0.2,1,0.2H22
	c0.4,0,0.6-0.1,1-0.2L15.5,9z"></path></svg> official@liftymedia.com</div>
            <div> <svg class="u-svg-content" viewBox="0 0 405.333 405.333" x="0px" y="0px" style="width: 1em; height: 1em;">
                  <path fill="currentColor" d="M373.333,266.88c-25.003,0-49.493-3.904-72.704-11.563c-11.328-3.904-24.192-0.896-31.637,6.699l-46.016,34.752    c-52.8-28.181-86.592-61.952-114.389-114.368l33.813-44.928c8.512-8.512,11.563-20.971,7.915-32.64    C142.592,81.472,138.667,56.96,138.667,32c0-17.643-14.357-32-32-32H32C14.357,0,0,14.357,0,32    c0,205.845,167.488,373.333,373.333,373.333c17.643,0,32-14.357,32-32V298.88C405.333,281.237,390.976,266.88,373.333,266.88z">
                  </path></svg> +62 (021) 39506895</div>
        </div>
      </div>
    </div>
    <div class="tips">
      <a class="u-link" href="https://nicepage.com/html-templates" target="_blank">
            <span>HTML Template </span>
        </a>
        <p class="u-text">
            <span>&nbsp;created with&nbsp;</span>
        </p>
        <a class="u-link" href="https://nicepage.studio" target="_blank">
            <span>Free Website Builder</span>
        </a>.
    </div>
  </div>
</template>
    
<script>
export default {
  name: "foot",
  props: {},
};
</script>
    
  
<style scoped>
body {
  line-height: 1.6;
}

img {
  object-fit: contain;
  background-size: contain;
}

.foot {
  width: 100%;
  color: #ffffff;
  background-color: #3c065e;

}

.box {
  width: 1140px;
  margin: auto;
  display: flex;
  min-height: 362px;
}

.footlogo {
  width: 317px;
  height: 87px;
  margin-top: 37px;
}

.follow {
  /* margin: 7px auto 0 40px; */

}

.follow p {
  font-size: 23px;
  margin-top: 24px;
  margin-left: 53px;
  font-weight: 400;
  line-height: 1.1;
}

.follow img {
  width: 237px;
  height: 55px;
  margin-top: 9px;
  margin-left: 39px;
}

.boxleftText {
  font-size: 14px;
  margin-top: 36px;
  margin-left: 56px;
}
.boxRight{
  /* width: 473px; */
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-top: 48px;
    margin-left: 87px;
}
.boxRight_dw {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 6px;
  margin-left: 15px;
}

.boxRight_dw .dw {
  width: 48px;
  height: 48px;
  margin-right: 5px;
}

.boxRight_item {
  width: 200px;
  margin-right: 42px;
  margin-bottom: 9px;
}
.boxRight_item03{
  margin-bottom: 18px;
  
}
.boxRight_item04{
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 17px;
  width: 245px;
  text-align: left !important;
  margin-bottom: 18px;
}
.boxRight_t{
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 2px;

}
.u-svg-content{
  margin-right: 15px;
}
.boxRight_tex {
  text-align: center;
  font-size: 14px;
}

.tips{
  color: #ffffff;
    background-color: #333333;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 1em;
    font-size: 16px;
    line-height: 1.6;
    
}
.u-link, .u-text{
  padding-top: 20px;
  padding-bottom: 20px;
}
.u-link{
  color: #adcce9;
}

</style>
    